<template>
<div class="grid-wrapper">
    <div class="left-column d-flex align-items-center justify-content-center" style="height: 100vh">
        <div class="reset-password-wrapper">
            <h2>Resetujte lozinku</h2>
            <form @submit.prevent="sendResetPasswordRequest">
                <div class="form-group">
                    <label>Email</label>
                    <input v-model="email" class="form-control" type="text" placeholder="Unesi email">
                </div>
                <div class="form-group">
                    <label>Reset kod</label>
                    <input v-model="resetCode" class="form-control" type="text" placeholder="Unesi reset kod">
                </div>
                <div class="form-group">
                    <label>Lozinka</label>
                    <input v-model="newPassword" class="form-control" type="text" placeholder="Unesi novu lozinku">
                </div>
                <button type="submit" class="btn reset-password-btn">Resetuj lozinku</button>
            </form>
        </div>
    </div>
    <div class="second-column"></div>
</div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router'
export default {
    setup() {
        const router = useRouter();
        const email = ref("");
        const resetCode = ref("");
        const newPassword = ref("");

        const sendResetPasswordRequest = () => {
            const body = {email: email.value, resetCode: resetCode.value, newPassword: newPassword.value};
            axios.post(`${process.env.VUE_APP_API_URL}/api/Accounts/reset-password`, body)
                .then(() => {
                    router.push('login');
                })
                .catch(() => {
                    // handle error
                })
        }

        return {
            email,
            resetCode,
            newPassword,
            sendResetPasswordRequest
        }
    },
}
</script>

<style scoped lang="scss">
.grid-wrapper {
    @include media(">=tablet") {
      display: grid;
      grid-template-columns: 1fr 1fr;  
    } 

    @include media("<=tablet") {
      display: flex;
      align-items: center;
      justify-content: center; 
    } 
}

    .reset-password-wrapper {
        width: 30vw;

        @include media("<=tablet") { 
            width: 80vw;
        }
    }

        h2 {
            color: color(blue);
            font-size: 28.42px;

            @include media("<=phone-large") { 
                font-size: $h4-size!important;
            }
        }

        .form-group {
            margin-top: 20px;
        }

        .reset-password-btn {
            width: 200px;
            height: 40px;
            background: color(blue);
            color: white;
            float: right;
            margin-top: 20px;
        }

        .reset-password-btn:hover {
            color: white;
        }

    .second-column {
        @include media(">=tablet") {
            background: url(../../../assets/images/login-background.png);
            background-position: center;
            background-repeat: no-repeat; 
            background-size: cover;
        } 

        @include media("<=tablet") {
            display: none;
        } 
    }
</style>